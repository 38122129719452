import React , { useEffect, useState } from 'react';
import CircularProgressBar from './CircularProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactionStats } from '../features/transactions/transactionsSlice';
import axios from 'axios';

function DashHeader() {
    const dispatch = useDispatch();
    const transactionStats = useSelector(state => state.transactions.transactionStats) || {};
    const [percentageUser, setPercentage] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const base_Url = process.env.REACT_APP_API_URL;

    const TotalTannitVestedValue = transactionStats.totalTannitVested || 0;
    const TotalTannitWithdrownValue = transactionStats.totalTannitWithdrown || 0;
    const TotalTannitPurchedValue = transactionStats.totalTannitPurched || 0;
    const TotalTannitnoneVestedValue = transactionStats.totalTannitnoneVested || 0;
    const user = useSelector(state => state.auth.user);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { id } = user.claims;
    const investorId = id && id.toString();

    useEffect(() => {
        if (investorId) {
            dispatch(fetchTransactionStats(investorId));
        }
    }, [dispatch, investorId]);
    const currentBalance = TotalTannitVestedValue - TotalTannitWithdrownValue - TotalTannitPurchedValue;
    const fetchPercentage = async () => {
        try {
            const response = await axios.get(`${base_Url}/Progress/Count/${investorId}`);
            setPercentage(response.data.data[0]);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    if(isAuthenticated){
        fetchPercentage();
    }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light dash-head" id="navbarcontainer">
        <div className="container-fluid ">
            <a className="navbar-brand nav2" href="/"><img src="/assets/images/image_2023_12_15T09_13_37_552Z.png"
                    alt="img" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-main">
                    <li className="nav-item py-2">
                        <a className="nav-link d-flex gap-2 align-items-center" aria-current="page" href="/Dashboard"><span><i className="fas fa-home me-2"></i></span>Dashboard</a>
                    </li>

                    <li className="nav-item py-2">
                        <a className="nav-link d-flex gap-2 align-items-center active" href="/Properties">
                            <span><i className="fa fa-city me-2"></i></span>Properties</a>
                    </li>
                    <li className="nav-item py-2">
                        <a className="nav-link d-flex gap-2 align-items-center" href="/Marketplace">
                            <span><i className="fa fa-store me-2"></i></span>Marketplace</a>
                    </li>
                    <li className="nav-item py-2">
                        <a className="nav-link d-flex gap-2 align-items-center" href="/Forum"><span><i className="fa-regular fa-comments"></i></span>Forum</a>
                    </li>
                    <li className="nav-item py-2 d-lg-none d-md-flex">
                        <a className="nav-link d-flex gap-2 align-items-center" href="/Profile"><i className="fa fa-user-o me-2" aria-hidden="true"></i>My profile</a>
                    </li>
                    <li className="nav-item py-2 d-lg-none d-md-flex">
                        <a className="nav-link d-flex gap-2 align-items-center" href="/Logout"><i className="fa fa-sign-out me-2" aria-hidden="true"></i>Logout</a>
                    </li>
                </ul>
                {isAuthenticated?
                <div className="d-flex mb-3 py-2 justify-content-between px-3">
                    <div className="profile d-flex me-1">
                        <div className="profile-pic d-flex align-items-center">
                            <img src="/assets/images/hourglass.png" alt="img" />
                        </div>
                        <div className="profile-info d-flex align-items-center ">
                            <h3 className="head-name">NonVested</h3>
                            <a className="value" href="/wallet-two">{TotalTannitnoneVestedValue}</a>
                        </div>
                    </div>
                    <div className="profile d-flex">
                        <div className="profile-pic d-flex align-items-center">
                            <img src="/assets/images/Tannit coin.png" alt="img" />
                        </div>
                        <div className="profile-info d-flex align-items-center ">
                            <h3 className="head-name">Available</h3>
                            <a className="value" href="/wallet-two">{currentBalance}</a>
                        </div>
                    </div>

                    <button className="btn-6 " type="notification">
                        <img src="/assets/icons/Font awesome bell.svg" alt="img" /></button>
                    <div className="user-box d-flex m-0">
                        <div className="App">
                            <CircularProgressBar percentage={percentageUser} />
                        </div>
                        <div className="btn-group new-dropdown-btn  d-lg-flex d-none">
                          <button className="btn btn-secondary ndb2 dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                          <span> <img src="/assets/icons/dashheader.svg" alt="/" />   </span>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                            <li><a className="dropdown-item" href="/Profile">My profile</a></li>
                            <li><a className="dropdown-item" href="/Dashboard">Dashboard</a></li>
                            <li><a className="dropdown-item" href="/Logout">Logout</a></li>
                          </ul>
                        </div>
                    </div>
                </div>
                :<a href='/Login' className='btnlogin'>Sign up | Login</a>
                }
            </div>
        </div>
    </nav>
    );
 }
 export default DashHeader;
